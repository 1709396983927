<template>
  <BModal
    id="modal-transfer-now"
    ref="modal-transfer-now"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-footer
    hide-header
    size="md"
    no-stacking
  >
    <div class="text-center">
      <div class="pt-2">
        <span class="text-2xl font-semibold text-black">
          Apakah kamu yakin ingin mentransfer nominal penarikan saldo?
        </span>
        <div class="text-lg text-[#626262] mt-1 font-normal">
          Pilih jawaban kamu
        </div>
        <br>
        <div class="mb-1">
          <BButton
            variant="outline-primary"
            style="border-color: unset"
            @click="$bvModal.hide('modal-transfer-now')"
          >
            Tidak
          </BButton>
          <BButton
            variant="primary"
            class="ml-1"
            :disabled="disableBtn"
            @click="onFinish"
          >
            <BSpinner
              v-if="isLoading"
              variant="light"
              small
            />
            Yakin
          </BButton>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { axiosIns } from '@/libs/axios'

export default {
  props: {
    getDetailWithdrawal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      idWithdrawal: this.$route.params.id,
      disableBtn: false,
    }
  },
  methods: {
    async onFinish() {
      this.isLoading = true
      this.disableBtn = true
      const url = `/kmpoin/mitra/update/status-wd/process/${this.idWithdrawal}`
      await axiosIns.post(url)
        .then(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Transfer Saldo Berhasil!',
              variant: 'success',
            },
          }, { timeout: 1000 })
          this.$bvModal.hide('modal-transfer-now')
          this.getDetailWithdrawal()
          this.isLoading = true
          this.disableBtn = true
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, { timeout: 1000 })
          this.isLoading = false
          this.disableBtn = true
        })
    },
  },
}
</script>
