<template>
  <BModal
    id="modal-reject"
    ref="modal-reject"
    no-stacking
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    size="lg"
    title="Buat Catatan Penolakan Penarikan"
  >
    <BFormGroup
      label=""
      label-for="catatan-penarikan-textarea"
      invalid-feedback="Silahkan isi catatan"
    >
      <BFormTextarea
        v-model="notes"
        placeholder="Berikan catatan kamu untuk partner dalam penarikan"
        rows="8"
      />
    </BFormGroup>

    <div class="w-100 flex justify-end">
      <BButton
        variant="outline-primary"
        class="mr-1 text-center"
        @click="$bvModal.hide('modal-reject')"
      >
        Kembali
      </BButton>
      <BButton
        variant="primary"
        class="text-center"
        :disabled="notes === '' || disableBtn"
        @click="onFinish"
      >
        <BSpinner
          v-if="isLoading"
          variant="light"
          small
        />
        Tolak & Sumbit
      </BButton>
    </div>
  </BModal>
</template>

<script>
import { BFormTextarea, BFormGroup, BButton } from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { axiosIns } from '@/libs/axios'

export default {
  components: {
    BFormTextarea,
    BFormGroup,
    BButton,
  },
  props: {
    getDetailWithdrawal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      notes: '',
      idWithdrawal: this.$route.params.id,
      isLoading: false,
      disableBtn: false,
    }
  },
  methods: {
    async onFinish() {
      this.isLoading = true
      this.disableBtn = true
      const url = `/kmpoin/mitra/update/status-wd/${this.idWithdrawal}`
      const payload = {
        status: 'rejected',
        notes: this.notes,
      }

      await axiosIns.post(url, payload)
        .then(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Withdrawal Berhasil DiTolak',
              variant: 'success',
            },
          }, { timeout: 1000 })
          this.$bvModal.hide('modal-reject')
          this.getDetailWithdrawal()
          this.isLoading = false
          this.disableBtn = true
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, { timeout: 1000 })
          this.isLoading = false
          this.disableBtn = true
        })
    },
  },
}
</script>
